/*
=====================================================================
*   Ceevee v1.0 Layout Stylesheet
*   url: styleshout.com
*   03-18-2014
=====================================================================

   TOC:
   a. General Styles
   b. Header Styles
   c. About Section
   d. Resume Section
   e. Portfolio Section
   f. Call To Action Section
   g. Testimonials Section
   h. Contact Section
   i. Footer

===================================================================== */

/* ------------------------------------------------------------------ */
/* a. General Styles
/* ------------------------------------------------------------------ */

/* ------------------------------------------------------------------ */
/* b. Header Styles
/* ------------------------------------------------------------------ */

.bgstyle {
  /* background: linear-gradient(136deg, #1ad7c0, #8E44AD, #ff9b11, #1F3A93, #ddd8c6, #000000, #000000);  */
  background: white;

  background-size: 1200% 1200%;
  -webkit-animation: AnimationName 70s ease infinite;
  -moz-animation: AnimationName 70s ease infinite;
  -o-animation: AnimationName 70s ease infinite;
  animation: AnimationName 70s ease infinite;
  height: 100%;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@-o-keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 7%;
  }
  50% {
    background-position: 100% 94%;
  }
  100% {
    background-position: 0% 7%;
  }
}

.socialicons {
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
}

.socialiconshover {
  color: black;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;
}

/* scrolldown link */
header .scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;

  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
header .scrolldown a:hover { color: #11ABB0; }
